import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
	return (
		<div className="flex flex-col font-sans min-h-screen text-gray-900 antialiased">
			<Header />

			<main className="flex flex-col flex-1 md:justify-center mx-auto w-full">{children}</main>

			<footer className="bg-primary">
				<nav className="flex justify-between items-center container mx-auto py-12 md:flex-wrap md:text-center md:justify-center">
					<p className="text-white md:order-2">© 2019 97th Floor</p>

					<p className="md:mb-10 md:order-1">
						<a
							className="font-bold no-underline text-white"
							href="https://github.com/taylorbryant/gatsby-starter-tailwind"
						>
							<svg
								className="fill-white"
								height="54.1"
								viewBox="0 0 269.605 54.1"
								width="269.605"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g transform="translate(-6.406 -8.649)">
									<path
										d="M87.054,12.6V25.019H103.5v3.892H87.054v15.12H83V8.71h22.39V12.6Z"
										transform="translate(26.903 0.021)"
									/>
									<path d="M123.5,40.18v3.838H104V8.71h4.054V40.18Z" transform="translate(34.279 0.021)" />
									<path
										d="M148.6,11.015a18.066,18.066,0,0,1,6.756,6.445,17.471,17.471,0,0,1,0,17.931,18.093,18.093,0,0,1-6.756,6.472,19.282,19.282,0,0,1-18.593,0,18.08,18.08,0,0,1-6.756-6.472,17.025,17.025,0,0,1-2.473-8.986,16.971,16.971,0,0,1,2.473-8.972A17.836,17.836,0,0,1,130.009,11a18.917,18.917,0,0,1,9.337-2.351,18.62,18.62,0,0,1,9.256,2.365Zm-16.458,3.338a14.526,14.526,0,0,0-5.27,5.054,13.012,13.012,0,0,0-1.973,7,13.175,13.175,0,0,0,1.973,7.04,14.539,14.539,0,0,0,12.458,6.918A14.337,14.337,0,0,0,151.7,33.445a13.31,13.31,0,0,0,1.946-7.04,13.12,13.12,0,0,0-1.946-7.013,14.431,14.431,0,0,0-5.216-5.04,14.148,14.148,0,0,0-7.148-1.878A14.337,14.337,0,0,0,132.144,14.352Z"
										transform="translate(40.172 0)"
									/>
									<path
										d="M178.312,11.015a18.066,18.066,0,0,1,6.756,6.445,17.472,17.472,0,0,1,0,17.931,18.093,18.093,0,0,1-6.756,6.472,19.282,19.282,0,0,1-18.593,0,18.08,18.08,0,0,1-6.756-6.472,17.026,17.026,0,0,1-2.473-8.986,16.972,16.972,0,0,1,2.473-8.972A17.837,17.837,0,0,1,159.719,11a18.917,18.917,0,0,1,9.337-2.351,18.62,18.62,0,0,1,9.256,2.365Zm-16.458,3.338a14.526,14.526,0,0,0-5.27,5.054,13.012,13.012,0,0,0-1.973,7,13.175,13.175,0,0,0,1.973,7.04,14.539,14.539,0,0,0,12.458,6.918,14.337,14.337,0,0,0,12.364-6.918,13.31,13.31,0,0,0,1.946-7.04,13.12,13.12,0,0,0-1.946-7.013,14.432,14.432,0,0,0-5.216-5.04,14.148,14.148,0,0,0-7.148-1.878A14.337,14.337,0,0,0,161.854,14.352Z"
										transform="translate(50.607 0)"
									/>
									<path
										d="M199.106,32.776q-1.5.095-2.365.095h-9.378V44.031H183.31V8.71h13.418q6.594,0,10.256,3.108a10.81,10.81,0,0,1,3.662,8.77,12.337,12.337,0,0,1-2.081,7.256,11.283,11.283,0,0,1-5.8,4.229l8.256,12h-4.581Zm5.067-5.891a7.54,7.54,0,0,0,2.594-6.2,7.216,7.216,0,0,0-2.594-6.04,11.783,11.783,0,0,0-7.432-2.04h-9.378v16.4h9.378a11.485,11.485,0,0,0,7.445-2.108Z"
										transform="translate(62.135 0.021)"
									/>
									<path
										d="M52.54,10.331h4.81V23.52h1.689V10.331H63.89V8.71H53.243Z"
										transform="translate(16.204 0.021)"
									/>
									<path
										d="M72.977,8.71V15.4H64.059V8.71H62.37V23.52h1.689V17.007h8.918V23.52h1.7V8.71Z"
										transform="translate(19.657 0.021)"
									/>
									<path
										d="M24.626,8.71A18.2,18.2,0,0,0,19.37,44.342h0a18.026,18.026,0,0,0,4.31.757l2.608-6.013a12.378,12.378,0,0,1-1.635.108,12.161,12.161,0,0,1-2.324-.23h0a12.269,12.269,0,1,1,13.512-6.9h0L22.518,62.624h6.472L41.422,33.978h0a18.215,18.215,0,0,0-3.338-19.336h18.39L35.585,62.624h6.486L65.528,8.71Z"
										transform="translate(0 0.021)"
									/>
									<path
										d="M58.556,42.581A4.216,4.216,0,0,1,60.2,44.176a4.459,4.459,0,0,1,.595,2.3,4.405,4.405,0,0,1-.554,2.284,4.27,4.27,0,0,1-1.689,1.594,4.9,4.9,0,0,1-2.392.581H52.57V42h3.662A4.864,4.864,0,0,1,58.556,42.581Zm-4.527,7h2.189a3.121,3.121,0,0,0,1.554-.392,2.865,2.865,0,0,0,1.108-1.108,3.189,3.189,0,0,0,.405-1.608,3.135,3.135,0,0,0-.419-1.608,2.986,2.986,0,0,0-1.135-1.122,3.162,3.162,0,0,0-1.581-.405H54.083Z"
										transform="translate(16.215 11.714)"
									/>
									<path d="M59.88,50.932V42h1.513v8.918Z" transform="translate(18.782 11.714)" />
									<path
										d="M70.342,46.54v3.3A6.054,6.054,0,0,1,66.869,51a4.824,4.824,0,0,1-2.378-.595,4.432,4.432,0,0,1-2.311-3.932,4.284,4.284,0,0,1,.635-2.284,4.486,4.486,0,0,1,1.73-1.621A5.013,5.013,0,0,1,66.963,42a5.269,5.269,0,0,1,1.851.338,5.148,5.148,0,0,1,1.581.905l-.865,1.108a3.811,3.811,0,0,0-1.2-.757,3.7,3.7,0,0,0-1.351-.27,3.283,3.283,0,0,0-1.635.419,3.141,3.141,0,0,0,.014,5.5A3.311,3.311,0,0,0,67,49.662a3.621,3.621,0,0,0,1.027-.162,4.27,4.27,0,0,0,1.013-.446V46.54Z"
										transform="translate(19.59 11.714)"
									/>
									<path d="M69.79,50.932V42h1.5v8.918Z" transform="translate(22.263 11.714)" />
									<path
										d="M76.174,43.351v7.567h-1.5V43.351H71.85V42H79v1.351Z"
										transform="translate(22.986 11.714)"
									/>
									<path
										d="M79.012,48.918l-.851,2.013H76.58L80.526,42h1.54l3.892,8.918H84.363L83.512,48.9Zm2.189-5.31-1.689,3.959h3.365Z"
										transform="translate(24.648 11.714)"
									/>
									<path d="M89.775,49.567v1.351h-5.4V42h1.5v7.567Z" transform="translate(27.384 11.714)" />
									<path
										d="M90.358,47.682a.824.824,0,0,1,.23.595.865.865,0,0,1-.23.608.811.811,0,0,1-1.149,0,.851.851,0,0,1-.23-.608.806.806,0,0,1,1.378-.595Z"
										transform="translate(29.003 13.626)"
									/>
									<path
										d="M101.96,50.932V44.27L99.136,50H98.082l-2.7-5.729v6.662H93.92V42H95.7l2.946,5.932L101.582,42h1.77v8.918Z"
										transform="translate(30.738 11.714)"
									/>
									<path
										d="M104.209,48.918l-.851,2.013H101.79L105.722,42h1.54l3.892,8.918h-1.608l-.851-2.013Zm2.243-5.3-1.676,3.959h3.365Z"
										transform="translate(33.502 11.714)"
									/>
									<path
										d="M113.627,48.232H111.1v2.7h-1.5V42h3.621a4.054,4.054,0,0,1,2.7.8,2.783,2.783,0,0,1,.946,2.257,3.257,3.257,0,0,1-.473,1.811,2.8,2.8,0,0,1-1.351,1.081l2,2.986h-1.7Zm1.189-1.8a1.635,1.635,0,0,0,.554-1.351,1.54,1.54,0,0,0-.554-1.351,2.567,2.567,0,0,0-1.608-.432h-2.121v3.54h2.121a2.486,2.486,0,0,0,1.621-.392Z"
										transform="translate(36.246 11.712)"
									/>
									<path
										d="M119.433,47l-1.513,1.662v2.27h-1.5V42h1.5v4.621L122.176,42h1.784L120.5,45.81l3.689,5.108h-1.811Z"
										transform="translate(38.641 11.714)"
									/>
									<path
										d="M124.7,43.351V45.77h4.351v1.351H124.7V49.58h5v1.351H123.17V42h6.378v1.351Z"
										transform="translate(41.012 11.714)"
									/>
									<path
										d="M132.914,43.351v7.567h-1.5V43.351H128.59V42h7.148v1.351Z"
										transform="translate(42.916 11.714)"
									/>
									<path d="M134.83,50.932V42h1.5v8.918Z" transform="translate(45.107 11.714)" />
									<path
										d="M143.926,50.932,139.17,44.54v6.391h-1.5V42h1.486l4.783,6.418V42h1.486v8.918Z"
										transform="translate(46.105 11.714)"
									/>
									<path
										d="M152.752,46.547v3.3a6.054,6.054,0,0,1-3.473,1.162,4.824,4.824,0,0,1-2.378-.595,4.432,4.432,0,0,1-2.311-3.932,4.283,4.283,0,0,1,.635-2.284,4.486,4.486,0,0,1,1.73-1.621,5.013,5.013,0,0,1,2.419-.595,5.27,5.27,0,0,1,1.851.338,5.148,5.148,0,0,1,1.581.905l-.865,1.108a3.811,3.811,0,0,0-1.2-.757,3.7,3.7,0,0,0-1.351-.27,3.284,3.284,0,0,0-1.635.419,3.141,3.141,0,0,0,.014,5.5,3.311,3.311,0,0,0,1.648.419,3.622,3.622,0,0,0,1.027-.162,4.27,4.27,0,0,0,1.013-.446V46.547Z"
										transform="translate(48.535 11.707)"
									/>
									<path
										d="M153.148,47.682a.824.824,0,0,1,.23.595.865.865,0,0,1-.23.608.811.811,0,0,1-1.149,0,.851.851,0,0,1-.23-.608.806.806,0,0,1,1.378-.595Z"
										transform="translate(51.057 13.626)"
									/>
									<path
										d="M158.19,43.351V45.77h4.324v1.351H158.19V49.58h5.027v1.351H156.69V42h6.378v1.351Z"
										transform="translate(52.785 11.714)"
									/>
									<path
										d="M168.255,49.567v1.351h-5.4V42H164.4v7.567Z"
										transform="translate(54.949 11.714)"
									/>
									<path
										d="M169.386,43.351V45.77h4.351v1.351h-4.351V49.58h5.027v1.351H167.9V42h6.378v1.351Z"
										transform="translate(56.723 11.714)"
									/>
									<path
										d="M176.817,50.932,173.25,42h1.621l2.7,7.256L180.4,42h1.567l-3.648,8.932Z"
										transform="translate(58.602 11.714)"
									/>
									<path
										d="M181.7,48.915l-.851,2.013H179.28l3.946-8.918h1.54l3.892,8.918h-1.649l-.851-2.013Zm2.243-5.3-1.689,3.959h3.405Z"
										transform="translate(60.72 11.718)"
									/>
									<path
										d="M189.984,43.351v7.567h-1.5V43.351H185.66V42h7.216v1.351Z"
										transform="translate(62.961 11.714)"
									/>
									<path
										d="M193.386,43.351V45.77h4.351v1.351h-4.351V49.58h5.027v1.351H191.9V42h6.378v1.351Z"
										transform="translate(65.152 11.714)"
									/>
									<path
										d="M204.117,42.581a4.2,4.2,0,0,1,1.649,1.594,4.459,4.459,0,0,1,.595,2.3,4.4,4.4,0,0,1-.595,2.284,4.27,4.27,0,0,1-1.662,1.594,4.9,4.9,0,0,1-2.392.581H198.09V42h3.662a4.864,4.864,0,0,1,2.365.581Zm-4.527,7h2.189a3.121,3.121,0,0,0,1.554-.392,2.865,2.865,0,0,0,1.108-1.108,3.189,3.189,0,0,0,.405-1.608,3.135,3.135,0,0,0-.419-1.608,2.986,2.986,0,0,0-1.135-1.122,3.162,3.162,0,0,0-1.581-.405H199.59Z"
										transform="translate(67.326 11.714)"
									/>
									<path
										d="M206.118,47.682a.824.824,0,0,1,.23.595.865.865,0,0,1-.23.608.811.811,0,0,1-1.149,0,.851.851,0,0,1-.23-.608.806.806,0,0,1,1.378-.595Z"
										transform="translate(69.662 13.626)"
									/>
								</g>
							</svg>
						</a>
					</p>
				</nav>
			</footer>
			<script
				async=""
				src="https://www.google-analytics.com/analytics.js"
				type="text/javascript"
			></script>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
