import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
	const { site } = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<header>
			<div className="container mx-auto py-8">
				<Link className="no-underline" to="/">
					<svg
						className="h-11 mr-2"
						height="52"
						viewBox="0 0 219 52"
						width="219"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							className="fill-primary"
							d="M20.946,27.545c0-3.241-2.667-5.2-6.935-5.2a12,12,0,0,0-8,3.441l-.84-1.334a13.336,13.336,0,0,1,9.055-3.761c5.2,0,8.495,2.56,8.495,6.668,0,2.52-2,5.761-5.081,8.975L7.2,47.121h17.5V48.8H5.183v-1.72L16.439,35.306C19.2,32.505,20.946,29.585,20.946,27.545Z"
							transform="translate(-5.17 3.381)"
						/>
						<path
							className="fill-primary"
							d="M43.92,34.736c0,9.095-3.6,14.1-10.215,14.1s-10.175-5-10.175-14.1,3.6-14.016,10.175-14.016S43.92,25.734,43.92,34.736Zm-18.737,0c0,8.175,3,12.576,8.535,12.576s8.575-4.4,8.575-12.576-3.041-12.5-8.575-12.5-8.548,4.374-8.548,12.5Z"
							transform="translate(0.954 3.391)"
						/>
						<path
							className="fill-primary"
							d="M54.73,20.783V33.919H73.667V20.783h1.68v28H73.68V35.559H54.73V48.775H53.05v-28Z"
							transform="translate(10.801 3.408)"
						/>
						<path
							className="fill-primary"
							d="M95.043,20.787v1.627H78.667V33.589H93.243v1.68H78.667V47.125h16.9v1.64H77v-28Z"
							transform="translate(18.79 3.405)"
						/>
						<path
							className="fill-primary"
							d="M98.1,20.783V47.135h12.429v1.64H96.42v-28Z"
							transform="translate(25.268 3.408)"
						/>
						<path
							className="fill-primary"
							d="M132.09,29.995c0,6-3.881,9.455-10.775,9.455H113.14v9.335h-1.68v-28h9.855C128.183,20.78,132.09,24.154,132.09,29.995Zm-1.6,0c0-4.921-3.361-7.615-9.215-7.615H113.14V37.81h8.135c5.841,0,9.2-2.774,9.2-7.775Z"
							transform="translate(30.285 3.411)"
						/>
						<path
							className="fill-primary"
							d="M139.6,22.427c-4.441,0-7.335,1.84-7.335,4.761,0,8.535,16.456,4.121,16.376,14.456,0,4.321-3.881,7.135-9.735,7.135a14.763,14.763,0,0,1-9.975-4l.88-1.48a13.189,13.189,0,0,0,9.135,3.8c4.8,0,7.895-2.08,7.895-5.334.08-8.775-16.376-4.281-16.376-14.376,0-4,3.681-6.668,9.215-6.668a15.5,15.5,0,0,1,8,2.28l-.76,1.52A14.069,14.069,0,0,0,139.6,22.427Z"
							transform="translate(36.112 3.391)"
						/>
						<path
							className="fill-secondary"
							d="M176.2,21.55a8.095,8.095,0,0,0-8.121-8.121h-2.667V10.762a8.095,8.095,0,0,0-8.121-8.121A8.215,8.215,0,0,0,149,10.775V29.791h19.017A8.215,8.215,0,0,0,176.2,21.55Z"
							transform="translate(42.807 -2.64)"
						/>
					</svg>
				</Link>
			</div>
		</header>
	);
}

export default Header;
